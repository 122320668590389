import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import H2 from '../components/styles/H2';
import P from '../components/styles/P';
import Spacer from '../components/styles/Spacer';
import CenteredMaxWidth from '../components/styles/CenteredMaxWidth';
import SEO from '../components/seo';
import THEME from '../theme';
import mediaqueries from '../components/mediaqueries';

const Subheading = styled.h4`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 14px;
  color: #a8a8a8;
`;

const Bio = styled.div`
  display: flex;
  flex-flow: wrap;

  flex-direction: ${props => (props.reverseOnSmall ? 'column-reverse' : 'row')};

  .text {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    text-align: justify;
    align-items: ${props => props.alignItems || 'flex-start'};
    margin-top: 40px;

    &.end {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .image {
    display: flex;
    flex-basis: 100%;
    justify-content: center;

    &.end {
      align-items: flex-end;
    }
  }

  ${mediaqueries.md`
    flex-direction: row;
    .text, .image {
      flex-basis: 50%;
      margin-top: 0;
    }
    .image.end {
      justify-content: flex-end;
    }
  `}
`;

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      matthew: file(relativePath: { eq: "matthew.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bethany: file(relativePath: { eq: "bethany.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="About us" />
      <CenteredMaxWidth width={960} style={{ padding: '0 40px' }}>
        <Spacer size={80} md={120} />
        {/* <Flex>
          <Flex style={{ flexBasis: '50%' }}>
            <Image style={{
              borderRadius: '20px',
              minWidth: '300px',
              boxShadow: THEME.shadows[3]
            }}
              fluid={data.bethany.childImageSharp.fluid}
              imgStyle={{
                objectPosition: 'center top',
              }}
            />
          </Flex>
          <Flex alignItems="flex-start" style={{ flexBasis: '50%', flexDirection: 'column', textAlign: 'justify' }}>
            <H2 style={{ fontWeight: 'bold' }}>Bethany Sarica</H2>
            <Subheading>Co-founder</Subheading>
            <Spacer size={20} />
            <P style={{ marginBottom: 0 }}>
              I am a Boston-born lady turned Bay Area settler. You can find me exploring local art studios, beach lounging and incessantly snacking when I am not creating. I love helping people bring their own creative ideas to fruition. I started my art journey through traditional mediums like ink and watercolor before channeling my energy to digital design, photography &amp; branding.
          </P>
          </Flex>
        </Flex>
        <Spacer size={200} />
        <Flex>
          <Flex alignItems="flex-end" style={{ flexBasis: '50%', flexDirection: 'column', justifyContent: 'flex-end', textAlign: 'justify' }}>
            <H2 style={{ fontWeight: 'bold' }}>Matthew Knudsen</H2>
            <Subheading>Co-founder</Subheading>
            <Spacer size={20} />
            <P style={{ marginBottom: 0 }}>
              I swerved from an accounting degree in the Land of Corn, Illinois, to web development in San Francisco in 2013 and haven’t looked back. I mean, turning around sometimes <em>is</em> one of my hobbies - along with rock climbing, doing puzzles, and making up dumb songs off the cuff - but I still love designing and coding websites as much as I did when I was learning it for the first time. And in those six years, I’ve even gotten pretty decent at it.
            </P>
          </Flex>
          <Flex alignItems="flex-end" style={{ flexBasis: '50%', justifyContent: 'flex-end' }}>
            <Image style={{
              borderRadius: '20px',
              minWidth: '300px',
              boxShadow: THEME.shadows[3]
            }} fluid={data.matthew.childImageSharp.fluid} />
          </Flex>
        </Flex> */}
        <Bio>
          <div className="image">
            <Image
              style={{
                borderRadius: '20px',
                minWidth: '300px',
                boxShadow: THEME.shadows[3],
              }}
              fluid={data.bethany.childImageSharp.fluid}
              imgStyle={{
                objectPosition: 'center top',
              }}
            />
          </div>
          <div className="text">
            <H2 style={{ fontWeight: 'bold' }}>Bethany Sarica</H2>
            <Subheading>Co-founder</Subheading>
            <Spacer size={20} />
            <P style={{ marginBottom: 0 }}>
              I am a Boston-born lady turned Bay Area settler. You can find me
              exploring local art studios, beach lounging and incessantly
              snacking when I am not creating. I love helping people bring their
              own creative ideas to fruition. I started my art journey through
              traditional mediums like ink and watercolor before channeling my
              energy to digital design, photography &amp; branding.
            </P>
          </div>
        </Bio>
        <Spacer md={200} size={120} />
        <Bio reverseOnSmall>
          <div className="text end">
            <H2 style={{ fontWeight: 'bold' }}>Matthew Knudsen</H2>
            <Subheading>Co-founder</Subheading>
            <Spacer size={20} />
            <P style={{ marginBottom: 0 }}>
              I swerved from an accounting degree in the Land of Corn, Illinois,
              to web development in San Francisco in 2013 and haven’t looked
              back. I mean, turning around sometimes <em>is</em> one of my
              hobbies - along with rock climbing, doing puzzles, and making up
              dumb songs off the cuff - but I still love designing and coding
              websites as much as I did when I was learning it for the first
              time. And in those six years, I’ve even gotten pretty decent at
              it.
            </P>
          </div>
          <div className="image end">
            <Image
              style={{
                borderRadius: '20px',
                minWidth: '300px',
                boxShadow: THEME.shadows[3],
              }}
              fluid={data.matthew.childImageSharp.fluid}
            />
          </div>
        </Bio>
      </CenteredMaxWidth>
      <Spacer size={200} />
    </Layout>
  );
};

export default AboutPage;
